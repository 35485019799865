import type { Payload } from "@local/payload-client/src/types";
import type { ReactNode } from "react";
import { ContentBlockServer } from "./ContentBlock";
import Image from "next/image";
import { RippedPageSvg2 } from "./ui/RippedPage";
import { barlowCondensed } from "@/utils/fonts";
import BunnyLoader from "@local/utils/src/bunnyLoader";
interface Props {
  block: Payload.ServicePromiseBlock;
}
const Icon = ({
  children,
  title,
  className
}: {
  children: ReactNode;
  title: string;
  className?: string;
}) => <div className={`flex flex-col items-center justify-between h-full px-2 gap-y-6 ${className}`} data-sentry-component="Icon" data-sentry-source-file="ServicePromise.tsx">
    <span className="flex items-center justify-center h-16">{children}</span>
    <p className={`${barlowCondensed.className} uppercase text-xl text-white`} dangerouslySetInnerHTML={{
    __html: title
  }} />
  </div>;
const ServicePromise = ({
  block
}: Props) => {
  return <div className={block.darkBackground ? "text-white" : "text-gray-900"} data-sentry-component="ServicePromise" data-sentry-source-file="ServicePromise.tsx">
      <ContentBlockServer className="relative pt-6 pb-8 mt-16 text-center" fullWidthBgColor={block.darkBackground ? "bg-[#17181A]" : "bg-[#3A402B]"} touchEdges data-sentry-element="ContentBlockServer" data-sentry-source-file="ServicePromise.tsx">
        <div className={`absolute w-full z-0 -top-3 sm:-top-5 md:-top-6 lg:-top-8 rotate-180 ${block.darkBackground ? "text-[#17181A]" : "text-[#3A402B]"}`}>
          <RippedPageSvg2 data-sentry-element="RippedPageSvg2" data-sentry-source-file="ServicePromise.tsx" />
        </div>
        <ul className="relative z-10 flex flex-row items-end justify-around max-w-6xl mx-auto">
          {block.icons.map((icon, index) => <Icon key={index} title={icon.heading} className={index >= 3 ? "hidden sm:block" : ""}>
              <Image src={icon.image.url} alt={`${icon.heading} Icon`} width={icon.image.width} height={icon.image.height} className="h-20 -mb-2" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
            </Icon>)}
        </ul>
      </ContentBlockServer>
    </div>;
};
export default ServicePromise;